import React from 'react';
import Table, { OverflowTableWrapper } from '../../components/Table';
import DatabasePage, { LastRow } from '../../components/DatabasePage';
import MySqlColorless from '../../images/databases/MySqlColorless.svg';

function MySql() {
  return (
    <DatabasePage
      title="Ametnes for MySQL"
      description="Ametnes for MySQL is a fully managed SQL database, deployable in the cloud of your choice. Snap it into your existing workflows with the click of a button, automate away the mundane tasks, and focus on building your core apps."
      featuresTitle="Ametnes MySQL Key Features"
      DbIcon={MySqlColorless}
    >
      <OverflowTableWrapper>
        <Table>
          <thead>
            <tr>
              <th>Features</th>
              <th>MySQL 5.7</th>
              <th>MySQL 8.0</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Free Tier</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Premium Tier</td>
              <td />
              <td>✕</td>
            </tr>
            <tr>
              <td>1 day backup retention</td>
              <td>✕</td>
              <td />
            </tr>
            <tr>
              <td>7 day backup retention</td>
              <td />
              <td>✕</td>
            </tr>
            <tr>
              <td>Up to 1 database per account</td>
              <td>✕</td>
              <td />
            </tr>
            <tr>
              <td>Secure TLS access</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>Automated backups</td>
              <td>✕</td>
              <td>✕</td>
            </tr>
            <tr>
              <td>High availability</td>
              <td />
              <td>✕</td>
            </tr>
            <tr>
              <td>Unlimited databases</td>
              <td />
              <td>✕</td>
            </tr>
            <tr>
              <td>Shared Instance</td>
              <td />
              <td>✕</td>
            </tr>
            <tr>
              <td>Dedicated Instance</td>
              <td />
              <td>✕</td>
            </tr>
            <tr>
              <td>On demand database storage</td>
              <td />
              <td>✕</td>
            </tr>
            <tr>
              <td>Pay only for what you use</td>
              <td />
              <td>✕</td>
            </tr>
            <LastRow productCells={2} />
          </tbody>
        </Table>
      </OverflowTableWrapper>
    </DatabasePage>
  );
}

export default MySql;
